.footer{
    height: 40px;
    text-align: center;
    background: #eceff170;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #263238;
    position: absolute;
    left: 0px;
    width: 100vw;
    bottom: 0px;
    line-height: 40px;
}